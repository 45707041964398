import { Role } from "@prisma/client";
import {
  type ActionFunctionArgs,
  redirect,
  type LoaderFunctionArgs,
  type MetaFunction,
} from "@remix-run/node";
import { withZod } from "@remix-validated-form/with-zod";
import { updateStatus, updateStatusSchema } from "~/models/user.server";
import { requireUserId, requireUserRole } from "~/utils/auth.server";

export const meta: MetaFunction = () => {
  return [
    { title: "Mvoice" },
    { name: "description", content: "Welcome to Mvoice!" },
  ];
};

export async function loader({ request }: LoaderFunctionArgs) {
  await requireUserId(request);
  const role = await requireUserRole(request);
  const redirectTo = role === Role.Agent ? "/calls" : "/dashboard";
  throw redirect(redirectTo, { status: 303 });
}

export const action = async ({ request }: ActionFunctionArgs) => {
  await requireUserRole(request);

  const result = await withZod(updateStatusSchema).validate(
    await request.formData(),
  );

  if (result.error) {
    throw new Response("Error data validation failed", { status: 400 });
  }

  await updateStatus(request, result.data);

  return {
    success: true,
    userId: result.data.userId,
    message: "Status updated successfully.",
  };
};
